// Generated by Framer (1e15e23)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Container, getFonts, Stack, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import GenericImageDistortion from "../codeFile/Test.tsx";
const GenericImageDistortionFonts = getFonts(GenericImageDistortion);

const cycleOrder = ["Cz_pbTiYh"];

const breakpoints = {}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"Cz_pbTiYh": "framer-v-myjonx"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("Cz_pbTiYh", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "Cz_pbTiYh", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "Cz_pbTiYh", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className={cx("framer-rjwtH")} style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<Stack {...restProps} className={cx("framer-myjonx", className)} style={{...style}} name="Project flow view"  direction="vertical" distribution="center" alignment="center" gap={10} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "100%", "padding": "0px 0px 0px 0px"}} ref={ref} {...addVariantProps("Cz_pbTiYh")}><Stack className="framer-5xk7il"   background={null} direction="horizontal" distribution="space-evenly" alignment="center" gap={10} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "100%", "padding": "0px 0px 0px 0px"}} {...addVariantProps("T5UhjjukC")}><Container className="framer-1j40em-container"  {...addVariantProps("LN3xzfo7u-container")}><GenericImageDistortion width="100%" height="100%" layoutId="LN3xzfo7u" id="LN3xzfo7u" image="https://qsxfdqhsuyovskknxkaj.supabase.co/storage/v1/object/public/imagefetch/tezos-WN5_7UBc7cw-unsplash.jpg" distortionAmount={0.04} rotationSpeed={0.25} borderRadiusTopLeft={50} borderRadiusTopRight={50} borderRadiusBotRight={50} borderRadiusBotLeft={50} style={{"width": "100%", "height": "100%"}} {...addVariantProps("LN3xzfo7u")}/></Container><Container className="framer-6nyg9t-container"  {...addVariantProps("zT9EORhlX-container")}><GenericImageDistortion width="100%" height="100%" layoutId="zT9EORhlX" id="zT9EORhlX" image="https://qsxfdqhsuyovskknxkaj.supabase.co/storage/v1/object/public/imagefetch/tezos-WN5_7UBc7cw-unsplash.jpg" distortionAmount={0.04} rotationSpeed={0.25} borderRadiusTopLeft={50} borderRadiusTopRight={50} borderRadiusBotRight={50} borderRadiusBotLeft={50} style={{"width": "100%", "height": "100%"}} {...addVariantProps("zT9EORhlX")}/></Container></Stack></Stack>
<div id="overlay"/>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-rjwtH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rjwtH .framer-myjonx { position: relative; width: 1134px; height: 759px; background-color: #000000; }", ".framer-rjwtH .framer-5xk7il { position: relative; overflow: visible; z-index: 1; width: 100%; height: 100%; flex: none; }", ".framer-rjwtH .framer-1j40em-container, .framer-rjwtH .framer-6nyg9t-container { position: relative; width: 500px; height: 500px; flex: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 759
 * @framerIntrinsicWidth 1134
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FramerhzPHBE1YS: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerhzPHBE1YS;

FramerhzPHBE1YS.displayName = "Projectview";

FramerhzPHBE1YS.defaultProps = {"width": 1134, "height": 759};

addFonts(FramerhzPHBE1YS, [...GenericImageDistortionFonts]);